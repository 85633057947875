import React from "react"

const Dashboard = () => {
    return (
        <React.Fragment>
            <main id="dashboard-page">
                <section className="overview">
                    <div className="card-header">
                       <h2 className="text-primary">
                           <i className="fa fa-eye"></i>&nbsp;
                            Overview
                        </h2>
                    </div>
                    <div className="flex">
                        <div className="card text-blue">
                            <div className="flex justify-between">
                                <i className="fa fa-bullhorn xxxlarge"></i>
                                <span className="count">404</span>
                            </div>
                            <p>Campaigns</p>
                        </div>
                        <div className="card text-red">
                            <div className="flex justify-between">
                                <i className="fa fa-object-group xxxlarge"></i>
                                <span className="count">404</span>
                            </div>
                            <p>Ad Groups</p>
                        </div>
                        <div className="card text-orange">
                            <div className="flex justify-between">
                                <i className="fa fa-key xxxlarge"></i>
                                <span className="count">404</span>
                            </div>
                            <p>Keywords & Targets</p>
                        </div>
                        <div className="card text-purple">
                            <div className="flex justify-between">
                                <i className="fa fa-cube xxxlarge"></i>
                                <span className="count">404</span>
                            </div>
                            <p>Product Ads</p>
                        </div>
                    </div>
                </section>
                <section className="algorithm">
                    <div className="table-card">
                        <div className="card-header">
                            <h2 className="text-primary">
                                <i className="fa fa-cogs"></i>&nbsp;
                                Algorithmic Automations
                            </h2>
                        </div>
                        <div className="body">
                            <div className="row">
                                <div className="col">
                                    <i className="fa fa-cogs fa-4x fa-fw text-red"></i>
                                    <p>
                                        <strong>Auto-Mate <sup>TM</sup> </strong>
                                        AM engine will efficiently adjust your Targets and Keywords bids to help you reach your AcoS goals. The AM algorithm will calculate and adjust your bid up or down (based on your given thresholds) to help maximize sales all while staying within your AcoS limits.​
                                    </p>
                                </div>
                                <div className="col">
                                    <i className="fa fa-diamond fa-4x fa-fw text-red"></i>
                                    <p>
                                        <strong>KeyWord Miner <sup>TM</sup> </strong>
                                        KWM Engine will constantly scan your reports and add any relevant, converting Customer Search Term back into our system. This will organically grow your campaigns in a controlled "100% automated" environment. (This Automation works only on our system generated PGNs)
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <i className="fa fa-dot-circle-o fa-4x fa-fw text-red"></i>
                                    <p>
                                        <strong>Target Miner <sup>TM</sup> </strong>
                                        TM Engine will constantly scan your reports and add any relevant, converting ASIN back into your ASIN Ad-Group. This will organically grow your ad reach in a controlled "100% automated" way. (This Automation works only on our system generated PGNs)
                                    </p>
                                </div>
                                <div className="col">
                                    <i className="fa fa-bolt fa-4x fa-fw text-red"></i>
                                    <p>
                                        <strong>Term-Inator <sup>TM</sup> </strong>
                                        TI Engine will constantly scan your reports and negate any bleeding term where needed, improving your Auto, Broad and Phrase campaigns matching capabilities. (This Automation works only on our system generated PGNs)
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <i className="fa fa-times-circle fa-4x fa-fw text-red"></i>
                                    <p>
                                        <strong>ASIN-Ator <sup>TM</sup> </strong>
                                        AA Engine will constantly scan your reports and negate any bleeding ASIN where needed, improving your AUTO and PAT campaigns matching capabilities. (This Automation works only on our system generated PGNs)
                                    </p>
                                </div>
                                <div className="col">
                                    <i className="fa fa-gamepad fa-4x fa-fw text-red"></i>
                                    <p>
                                        <strong>Bid Gambler <sup>TM</sup> </strong>
                                        If you are bidding too low, BG will periodically increase the bid until you start getting clicks. Bid Gambler and Bid Nailer Engines work together to optimize the bid of each keyword in your account.​​
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="legacy">
                    <div className="table-card">
                        <div className="card-header">
                            <h2 className="text-primary">
                               <i className="fa fa-history"></i>&nbsp;
                                Legacy Exact Automations
                            </h2>
                        </div>
                        <div className="body">
                            <div className="row">
                                <div className="col">
                                    <i className="fa fa-shield fa-4x fa-fw text-red"></i>
                                    <p>
                                        <strong>Keyword Guardian <sup>TM</sup> </strong>
                                        KWG Engine will make sure that you are not overspending on any keyword, pausing them when they reach a certain spend threshold with no sales.Additionally, KWG will constantly check your account and pause low performing keywords, as well as re-enabling well-performing ones.
                                    </p>
                                </div>
                                <div className="col">
                                    <i className="fa fa-recycle fa-4x fa-fw text-red"></i>
                                    <p>
                                        <strong>KeyWord Recycler <sup>TM</sup> </strong>
                                        KWR Engine is always on the lookout for any low performing keyword that has been paused and calculate a new, updated optimal bid based on our algorithms. KWR will test this new keyword/bid pair in a separate set-up for easier result tracking.
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <i className="fa fa-gavel fa-4x fa-fw text-red"></i>
                                    <p>
                                        <strong>Bid Nailer <sup>TM</sup> </strong>
                                        BN will work constantly toward adjusting bids based on your keywords' average cost per click and your strategy. Bid Nailer and Bid Gambler Engines work together to optimize the bid of each keyword in your account.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="news-feed">
                    <div className="table-card">
                        <div className="card-header">
                           <h2 className="text-primary">
                               <i className="fa fa-newspaper-o"></i>&nbsp;
                                News Feed
                            </h2>
                        </div>
                        <div className="news">
                            <div className="row">
                                <p>
                                    <i className="fa fa-bullhorn text-blue"></i>
                                    Last Campaign is <strong>FM-WDJ</strong> with a daily budget of <strong>$6.00</strong>
                                </p>
                                <p>265 Days</p>
                            </div>
                            <div className="row">
                                <p>
                                    <i className="fa fa-bullhorn text-blue"></i>
                                    Last Campaign is <strong>FM-WDJ</strong> with a daily budget of <strong>$6.00</strong>
                                </p>
                                <p>265 Days</p>
                            </div>
                            <div className="row">
                                <p>
                                    <i className="fa fa-bullhorn text-blue"></i>
                                    Last Campaign is <strong>FM-WDJ</strong> with a daily budget of <strong>$6.00</strong>
                                </p>
                                <p>265 Days</p>
                            </div>
                        </div>
                    </div>
                </section>
     
            </main>
        </React.Fragment>
    )
}

export default Dashboard