import React from "react"
import Seo from "src/utils/seo"
import Layout from "components/Layout/Layout"
import Dashboard from "components/Dashboard/Dashboard"

export default function Home() {
    return(
        <React.Fragment>
            <Seo title="Dashboard" />
            <Layout showActions={false}>
                <Dashboard/>
            </Layout>
        </React.Fragment>
    )
}